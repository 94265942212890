<template>
    <el-form :model="form" label-width="380px" ref="ruleForm" label-position="top" :rules="rules">
    <div class="nav">
        <img
                style="height: 50px; padding-right: 12px; margin: 5px"
                src="/img/logo2.png"
                alt=""
        />
        <div style="margin: auto; font-size: 24px">{{$t('navTitle')}}</div>
        <div style="margin: auto; font-size: 12pt">{{$t('navSwitchLang')}}:
            <template v-for="(btn, i) in langBtns" :key="i">
                <el-button link :disabled="btn.disabled" :type="btn.type" style="margin-left: 0"
                           @click="changeLang(btn.locale)">
                    {{btn.text}}
                </el-button>
                <template v-if="langBtns.length !== i + 1">/</template>
            </template>
            <br/>{{$t('navCompanyNum')}}: {{companyCount}}</div>
    </div>
    <div class="mainBody">
        <div style="margin: 20px;">
            {{$t('body1')}}<br/><br/>
            {{$t('body2')}}
        </div>
        <div class="item" v-for="(section, j) in formSections" :key="j" :style="{ 'background-color': section.color }">
            <span style="font-weight: bold;">{{section.label1}}</span><br/>
            <div style="margin-bottom: 20px">{{section.label2}}</div>
            <div>
                <template v-for="(item, i) in this[section.itemsName]" :key="i">
                    <el-form-item :label="item.label + ':'" :prop="item.model">
                        <div >
                            <el-select v-model="form[item.model]" filterable placeholder="Select" v-if="item.dict" class="formItem">
                                <el-option
                                        v-for="item in this[item.dict]"
                                        :key="item.ID"
                                        :label="item.name"
                                        :value="item.ID"
                                />
                            </el-select>
                            <el-input v-model="form[item.model]" v-else class="formItem"/>
                            <i><span style="font-size: 14px;"> {{item.sidelabel}}</span></i>
                        </div>
                    </el-form-item>
                </template>
                <img v-if="section.sideIcon" :src="section.sideIcon" alt=""
                     style="height: auto; padding-right: 12px; margin: 5px"/>
            </div>
        </div>
        <div class="blockWithImg">
            <div>
                <div class="item" style="background-color: #e6f6ff;">
                    <span style="font-weight: bold;">{{$t('section.optionalInformation.label1')}}</span><br/>
                    <div style="margin-bottom: 20px">{{$t('section.optionalInformation.label2')}}</div>
                        <el-form-item :label="optionalInformation[0].label" :prop="optionalInformation[0].model">
                            <el-input v-model="form[optionalInformation[0].model]" :maxlength="optionalInformation[0].length" class="formItem2"/>
                        </el-form-item>
                        <el-form-item :label="optionalInformation[1].label" :prop="optionalInformation[1].model">
                            <el-input v-model="form[optionalInformation[1].model]" :maxlength="optionalInformation[1].length" class="formItem2"/>
                        </el-form-item>
                </div>
                <div style="display: flex; justify-content: center; margin-bottom: 20px;">
                    <el-button type="primary" size="large" style="font-size: 18px" @click="submitForm(ruleFormRef)">Submit</el-button>
                </div>
            </div>
            <img src="/img/sideIcon.png" alt="" class="sideImg"/>
        </div>
        <div style="margin: 20px;">
            {{$t('footer1')}}<br/>
            {{$t('footer2')}} <a href = "mailto: sm_se_ius@sweco.se">sm_se_ius@sweco.se</a><br/>
            {{$t('footer3')}}
        </div>
    </div>
    </el-form>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
  async beforeMount() {
    if (!this.lang) {
      localStorage.setItem('swecoLang', 'sv')
      this.lang = 'sv'
    }
    this.changeLang()
    fetch(`${this.ubHost}/getRegionDict`).then(response => {
      response.json().then(res => {
        this.regionDict = res
      })
    })
    const response2 = await fetch(`${this.ubHost}/getCompanyCount`)
    this.companyCount = (await response2.json() || 0).toString()
  },
  data() {
    return {
      lang: window.localStorage.getItem('swecoLang'),
      form: {
        materialWaste: '',
        waterSideStream: '',
        otherCompanyRequest: '',
        location: null,
        companyName: '',
        contactPerson: '',
        contactPersonEmail: '',
        companyNumber: null,
        otherLocaltion: '',
      },
      regionDict: [],
      companyCount: '0',
      buttons: [
        { type: '', text: 'plain' },
        { type: 'primary', text: 'primary' },
        { type: 'success', text: 'success' },
        { type: 'info', text: 'info' },
        { type: 'warning', text: 'warning' },
        { type: 'danger', text: 'danger' },
      ],
      requiredFields: ['location', 'companyName', 'contactPerson', 'contactPersonEmail']
    }
  },
  computed: {
    ruleFormRef() {
      return this.$refs.ruleForm
    },
    rules() {
      const rules = {}
      this.requiredFields.forEach(item => {
        rules[item]= [
          { required: true, message: this.requiredFieldMsg, trigger: 'change' },
        ]
      })
      return rules
    },
    formSections() {
      return [
        {
          label1: this.$t('section.sideStream.label1'),
          label2: this.$t('section.sideStream.label2'),
          itemsName: 'sideStreams',
          color: '#a3dcff'
        },
        {
          label1: this.$t('section.informationNeeded.label1'),
          label2: this.$t('section.informationNeeded.label2'),
          itemsName: 'informationNeeded',
          color: '#daf1ff'
        },
      ]
    },
    sideStreams () {
      return [
        {
          label: this.$t('section.sideStream.materialWaste'),
          model: 'materialWaste',
          sidelabel: this.$t('section.sideStream.materialWasteQ'),
        },
        {
          label: this.$t('section.sideStream.waterSideStream'),
          model: 'waterSideStream',
          sidelabel: this.$t('section.sideStream.waterSideStreamQ'),
        },
        {
          label: this.$t('section.sideStream.otherCompanyRequest'),
          model: 'otherCompanyRequest',
          sidelabel: this.$t('section.sideStream.otherCompanyRequestQ'),
        }
      ]
    },
    informationNeeded () {
      return [
        {
          label: this.$t('section.informationNeeded.location'),
          model: 'location',
          sidelabel: '',
          dict: 'regionDict'
        },
        {
          label: this.$t('section.informationNeeded.companyName'),
          model: 'companyName',
          sidelabel: ''
        },
        {
          label: this.$t('section.informationNeeded.contactPerson'),
          model: 'contactPerson',
          sidelabel: ''
        },
        {
          label: this.$t('section.informationNeeded.contactPersonEmail'),
          model: 'contactPersonEmail',
          sidelabel: ''
        },
      ]
    },
    optionalInformation () {
      return [
        {
          label: this.$t('section.optionalInformation.companyNumber'),
          model: 'companyNumber',
          sidelabel: '',
          length: 10
        },
        {
          label: this.$t('section.optionalInformation.otherLocaltion'),
          model: 'otherLocaltion',
          sidelabel: '',
        },
      ]
    },
    langBtns () {
      return [
        {
          text: 'SV',
          locale: 'sv',
          disabled: this.lang === 'sv',
          type: this.lang === 'sv' ? '' : 'primary'
        },
        {
          text: 'EN',
          locale: 'en',
          disabled: this.lang === 'en',
          type: this.lang === 'en' ? '' : 'primary'
        }
      ]
    },
    requiredFieldMsg () {
      return this.$t('requiredFieldMsg')
    },
    ubHost () {
      return process.env.VUE_APP_UB_HOST
    }
  },
  methods: {
    async submitForm (formEl) {
      if (!formEl) return
      await formEl.validate(async (valid) => {
        if (valid) {
          const response = await fetch(`${this.ubHost}/submitForm`, {
            method: "POST",
            body: JSON.stringify(this.form),
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
          })
          const res = await response.json()
          ElMessage({
            showClose: true,
            message: this.$t('submitSuccess'),
            type: 'success',
          })
          formEl.resetFields(Object.keys(this.form).filter(item => !(this.requiredFields.includes(item))))
          const response2 = await fetch(`${this.ubHost}/getCompanyCount`)
          this.companyCount = (await response2.json() || 0).toString()
        } else {
          ElMessage({
            showClose: true,
            message: this.$t('fullRequiredFieldsMsg'),
            type: 'error',
          })
        }
      })
    },
    changeLang(newLang) {
      if (newLang) {
        localStorage.setItem('swecoLang', newLang)
        this.lang = newLang
      }
      this.$i18n.locale = this.lang
    }
  }
};
</script>

<style>
body {
  background: rgb(247, 247, 248);
}

.nav {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    align-items: center;
    background-color: white;
}

.mainBody {
    background-color: white;
    max-width: max-content;
    margin: auto 70px;
    padding: 10px;
}

.item {
    margin: 20px;
    border-radius: 20px;
    padding: 20px;
    display: grid;
    justify-items: center;
}
.form-item {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 20px;
    align-items: center;
    width: 100%;
}

.form-label {
    text-align: right;
}

.el-form-item__label {
    font-family: "proba_pro_regular", serif;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: auto;
    text-align: right;
}
.formItem {
    display: flex;
    flex-direction: column;
}


@media (min-width: 1500px) {
    .formItem {
        width: 1000px;
    }
}
@media (min-width: 1065px) and (max-width: 1499px) {
    .formItem {
        width: 800px;
    }
}
@media (min-width: 860px) and (max-width: 1064px) {
    .formItem {
        width: 600px;
    }
}

@media (min-width: 1065px) {
    .blockWithImg {
        display: grid;
        grid-template-columns: 1fr auto;
    }
}
.sideImg {
    height: 270px;
}
@media (max-width: 1067px) {
    .sideImg {
        display: block;
    }
}

@media (min-width: 1504px) {
    .formItem2 {
        width: 760px;
    }
}
@media (min-width: 860px) and (max-width: 1064px) {
    .formItem2 {
        width: 600px;
    }
}

@media (max-width: 673px) {
    .sideImg {
        height: auto;
        width: 310px
    }
}
</style>
